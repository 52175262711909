@import "bulma/sass/utilities/initial-variables";
@import "bulma/sass/utilities/functions";
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700,200');

$orange: #FFBD21;

$yellow: #FFEC38;
$black: #202020;
$blue: #0065FF;
$green: #78cc88;
$red: #F15E5E;
$purple: #7044FF;
$purple-light: #eee8ff;
$light: #F1F3F5;
$dark: $grey-darker;
$color-twitter:    #1da1f2;
$color-facebook:   #3b5998;
$color-google:     #f04141;
$color-twitch:     #6441a5;
$color-whatsapp:   #128C7E;
$color-instagram:  #405DE6;
$color-discord:    #3b5998;
$color-youtube:    #FF0000;
$color-linkedin:   #0966c2;
$color-mailchimp:  #ffe01b;

$secondary: $orange;

$acqua: #44FFCE;
$acqua-darker: #2BA081;
$acqua-lighter: #E8FFF9;
$acqua-dark: #00CF99;

$purple-darker: #462A9F;
$purple-lighter:#EEE8FF;

// $yellow-darker: #FFF9B9;
$yellow-lighter: #FFF9B9;
$yellow-light: #FFEC38;
$yellow-dark: #F6CA2C;
$yellow-darker: #C8B92D;

$red-darker: #963A3A;

$family-serif: 'Montserrat', sans-serif;
$coin-color: $green;
$text: $dark-text;

$primary: $purple;
$warning: $yellow;
$body-background-color: #f4f6fb;
$info: $blue;
$image-background-color: $secondary;

$admin-color: $red;
$creator-color: $blue;

$premium-color: $yellow;
$premium-color-lighter: $yellow-lighter;
$premium-color-darker: $yellow-darker;
$premium-color-dark: $yellow-dark;

$vip-color: $acqua;
$vip-color-lighter: $acqua-lighter;
$vip-color-darker: $acqua-darker;
$vip-color-dark: $acqua-dark;

$premium-vip-color: $acqua;
$premium-vip-color-lighter: $acqua-lighter;
$premium-vip-color-darker: $acqua-darker;
$premium-vip-color-dark: $acqua-dark;


$link: $primary;
$input-focus-border-color: $primary;
// $timeline-icon-size: $size-4;
// $primary-invert: findColorInvert($purple);

// $family-primary: $family-serif;
// $family-serif: "Merriweather", "Georgia", serif;

// 3. Set the derived variables
// Use the new pink as the primary color

// Use the existing orange as the danger color
// $danger: $orange;
// Use the new serif family

// 4. Setup your Custom Colors
// $orange: #0077b5;
// $orange-invert: findColorInvert($orange);
// $twitter: #55acee;
// $twitter-invert: findColorInvert($twitter);
// $github: #333;
// $github-invert: findColorInvert($github);

// 5. Add new color variables to the color map.
// @import "../sass/utilities/derived-variables";
// $addColors: (
//   "twitter":($twitter, $twitter-invert),
//   "linkedin": ($linkedin, $linkedin-invert),
//   "github": ($github, $github-invert)
// );
// $colors: map-merge($colors, $addColors);

// 6. Import the rest of Bulma
// @import "../bulma";

// $link: $primary;
